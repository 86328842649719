import "./src/styles/global.scss";
import "./src/styles/typography.scss";
import "./src/styles/layout.scss";
import "./src/styles/_slick.scss";
import "./src/styles/_slick-theme.scss";

import React from "react";

import ReCaptchaProvider from "context/recaptcha";

export const wrapRootElement = ({ element }) => (
  <ReCaptchaProvider>{element}</ReCaptchaProvider>
);

export { wrapPageElement } from "utils/wrapPageElement";
